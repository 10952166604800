import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { LoadMechanics } from '@vandelft/modules/shared/state/users';
import { LoadCompanies } from '@vandelft/modules/shared/state/companies';
import { Report, User } from '@vandelft/modules/shared/models';
import {
  CreateReport,
  LoadReport,
  OpenReportsList,
  ReportsState,
  SaveReport,
} from '@vandelft/modules/shared/state/reports';
import { AuthState } from '@vandelft/modules/shared/state/auth';
import * as moment from 'moment';

@Component({
  selector: 'app-reports-form',
  templateUrl: './form.component.html',
})
export class FormComponent implements OnInit {
  @Select(ReportsState.report)
  public report$: Observable<Report>;

  @Select(AuthState.user)
  public user$: Observable<User>;

  public isNewReport = true;

  public isBusy = false;

  public form = new FormGroup({
    report: new FormControl(),
  });

  public constructor(
    private route: ActivatedRoute,
    private store: Store,
  ) {}

  ngOnInit(): void {
    const reportId: string | null = this.route.snapshot.paramMap.get('id');
    const events = [new LoadMechanics(), new LoadCompanies()];

    this.report$.subscribe((report: Report): void => {
      this.form.get('report').setValue(report);
    });

    if (reportId) {
      this.isNewReport = false;
      events.push(new LoadReport(reportId));
    }

    if (!reportId) {
      events.push(new CreateReport());
    }

    this.store.dispatch(events);
  }

  public async onSubmit(): Promise<void> {
    try {
      this.isBusy = true;
      const report = this.form.value.report;


      report.plannedStart = moment(`${report.plannedStart}`).tz('Europe/Amsterdam', true).utc().format('YYYY-MM-DD HH:mm');
      report.plannedEnd = moment(`${report.plannedEnd}`).tz('Europe/Amsterdam', true).utc().format('YYYY-MM-DD HH:mm');

      await firstValueFrom(this.store.dispatch(new SaveReport(report)));
      await firstValueFrom(this.store.dispatch(new OpenReportsList()));
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      this.isBusy = false;
    }
  }
}
