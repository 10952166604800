import * as moment from 'moment/moment';

export const datetimeToPlain = (value: any): string => {
  if (!value) {
    return value;
  }

  let effectiveValue = value?.value ?? value;
  effectiveValue = !!effectiveValue?.obj ? undefined : effectiveValue;

  return moment(effectiveValue).format('YYYY-MM-DD HH:mm:ss');
};
