<app-page-header title="Meldingen">
  <div class="actions ml-auto">
    <ng-container *ngIf="prefix === 'mechanic'">
      <a class="btn btn-primary ml-1" (click)="addWorkOrder($event)">toevoegen</a>
    </ng-container>
    <ng-container *ngIf="prefix !== 'mechanic'">
      <a class="btn btn-primary ml-1" (click)="addReport()">toevoegen</a>
    </ng-container>
  </div>
</app-page-header>

<div class="container">
  <div class="card">
    <div class="body">
      <form class="form" [formGroup]="searchForm">
        <div class="row">
          <div class="col col-65">
            <div class="form-group">
              <label class="label">Periode</label>
              <div class="input-group">
                <input type="date" class="form-control" formControlName="start" />
                <input type="date" class="form-control ml-1" formControlName="end" />
              </div>
            </div>
          </div>
        </div>

        <ng-container *ngIf="showFilters">
          <div class="row">
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Woonplaats</label>
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="city" />
                </div>
              </div>
            </div>
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Straat</label>
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="street" />
                </div>
              </div>
            </div>
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Huisnummer</label>
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="houseNumber" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Postcode</label>
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="postalCode" />
                </div>
              </div>
            </div>
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Telefoonnummer</label>
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="phoneNumber" />
                </div>
              </div>
            </div>
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Monteur</label>
                <ng-container *ngIf="mechanics$ | async as mechanics">
                  <select class="form-control" formControlName="mechanicId" [compareWith]="compareById">
                    <option [ngValue]="null">---- SELECTEER EEN MONTEUR ----</option>
                    <option *ngFor="let mechanic of mechanics" [ngValue]="mechanic.id">
                      {{ mechanic.fullName }}
                    </option>
                  </select>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Opdrachtgever</label>
                <ng-container *ngIf="companies$ | async as companies">
                  <select class="form-control" formControlName="companyId" [compareWith]="compareById">
                    <option [ngValue]="null">---- SELECTEER EEN OPDRACHTGEVER ----</option>
                    <option *ngFor="let company of companies" [ngValue]="company.id">
                      {{ company.name }}
                    </option>
                  </select>
                </ng-container>
              </div>
            </div>
            <div class="col col-32">
              <div class="form-group">
                <label class="label">Werkbonnummer</label>
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="number" />
                </div>
              </div>
            </div>
            <div class="col col-32"></div>
          </div>
        </ng-container>
      </form>
    </div>
  </div>

  <ng-container *ngIf="reports$ | async as reports; else loading">
    <div *ngFor="let status of statuses" class="status-group">
      <ng-container *ngIf="reports | stateFilter: status.value as stateReports">
        <div class="card mt-3">
          <div class="body">
            <h3>{{ status.label }}</h3>

            <ul class="list">
              <li class="no-results" *ngIf="stateReports?.length === 0">
                Er zijn geen meldingen gevonden voor de huidige zoekopdracht met de status '{{ status.label }}'
              </li>
              <ng-container *ngFor="let report of stateReports">
                <li
                  *ngIf="report.status === status.value"
                  class="py-1"
                  [class.needs-approval]="!report.approvedById || report.needsTimes">
                  <div class="row">
                    <div class="details">
                      <div class="row" (click)="view(report)">
                        <div class="col col-48">
                          <div class="bold">
                            {{ report.client.clientAddress.contact }}
                            <ng-container *ngIf="report.company">(via: {{ report.company.name }})</ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="muted row">
                        <div class="col col-40" (click)="view(report)">
                          <i>Adres</i>
                          {{ report.client.clientAddress.streetAndHouseNumber }}
                          <br />
                          {{ report.client.clientAddress.postalCode }} {{ report.client.clientAddress.city }}
                          <a href="tel:{{ report.client.clientAddress.phoneNumber }}">
                            {{ report.client.clientAddress.phoneNumber }}
                          </a>
                        </div>
                        <div class="col col-35" (click)="view(report)">
                          <i>Planning</i>
                          {{ report.mechanic?.fullName || 'Onbekend' }}
                          <br />
                          {{ report.plannedStart | date: 'dd-MM-yyyy':'Europe/Amsterdam' }}
                          &#64; {{ report.plannedStart | date: 'HH:mm':'Europe/Amsterdam' }} -
                          {{ report.plannedEnd | date: 'HH:mm':'Europe/Amsterdam' }}
                          <a href="https://maps.google.com/?daddr={{ report.client.clientAddress.fullAddress }}">
                            Route
                          </a>
                        </div>

                        <div class="col col-25">
                          <div class="actions actions-vertical">
                            <ng-container
                              *ngIf="
                                !['admin', 'planner'].includes(prefix) &&
                                report.status !== 'completed' &&
                                report.mechanicId === (user$ | async)?.id
                              ">
                              <a class="btn btn-sm btn-primary" (click)="addWorkOrder($event, report)">
                                Nieuwe werkbon
                              </a>
                            </ng-container>
                            <ng-container *ngIf="['admin', 'planner', 'financial'].includes(prefix)">
                              <a class="btn btn-secondary btn-outline btn-sm btn-icon" (click)="edit(report)">
                                <img src="/assets/img/pencil.svg" />
                              </a>

                              <a
                                *ngIf="report.needsTimes"
                                class="btn btn-secondary btn-outline btn-sm btn-icon ml-1"
                                (click)="setTimes($event, report)">
                                <img src="/assets/img/clock.svg" />
                              </a>
                              <a
                                *ngIf="!report.approvedById"
                                class="btn btn-primary btn-outline btn-sm btn-icon ml-1"
                                (click)="approve(report)">
                                <img src="/assets/img/approve.svg" />
                              </a>
<!--                              <a-->
<!--                                *ngIf="report.approvedById"-->
<!--                                class="btn btn-primary btn-outline btn-sm btn-icon ml-1"-->
<!--                                (click)="sendMessage(report)">-->
<!--                                <img src="/assets/img/chat.svg" />-->
<!--                              </a>-->
                              <a
                                *ngIf="['admin'].includes(prefix)"
                                class="btn btn-primary btn-outline btn-sm btn-icon ml-1"
                                (click)="deleteReport($event, report)">
                                <img src="/assets/img/trash.svg" />
                              </a>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-1" *ngIf="!!report.description">
                    <div class="col col-100 muted">
                      {{ report.description }}
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <div class="loader">
    <img class="loader" src="assets/img/loader-primary.svg" />
  </div>
</ng-template>
